<template>
  <main class="main-name">
    <div class="container main-name__container">
      <div class=" main-content-bg">
        <h3 class="main-name__title title-bg">
          Пожалуйста, представьтесь
        </h3>
        <div class="name-content">
          <input v-model="name" class="name-content__input input-value" type="text" ref="input_name">
          <ul class="keyboard keyboard__line">
            <li class="keyboard__item">й</li>
            <li class="keyboard__item">ц</li>
            <li class="keyboard__item">у</li>
            <li class="keyboard__item">к</li>
            <li class="keyboard__item">е</li>
            <li class="keyboard__item">н</li>
            <li class="keyboard__item">г</li>
            <li class="keyboard__item">ш</li>
            <li class="keyboard__item">щ</li>
            <li class="keyboard__item">з</li>
            <li class="keyboard__item">х</li>
            <li class="keyboard__item">ъ</li>
          </ul>

          <ul class="keyboard keyboard__line">
            <li class="keyboard__item">ф</li>
            <li class="keyboard__item">ы</li>
            <li class="keyboard__item">в</li>
            <li class="keyboard__item">а</li>
            <li class="keyboard__item">п</li>
            <li class="keyboard__item">р</li>
            <li class="keyboard__item">о</li>
            <li class="keyboard__item">л</li>
            <li class="keyboard__item">д</li>
            <li class="keyboard__item">ж</li>
            <li class="keyboard__item">э</li>
          </ul>
          <ul class="keyboard keyboard__line">
            <li class="keyboard__item">я</li>
            <li class="keyboard__item">ч</li>
            <li class="keyboard__item">с</li>
            <li class="keyboard__item">м</li>
            <li class="keyboard__item">и</li>
            <li class="keyboard__item">т</li>
            <li class="keyboard__item">ь</li>
            <li class="keyboard__item">б</li>
            <li class="keyboard__item">ю</li>
          </ul>
          <ul class="keyboard keyboard__line">
            <li @click="addSpace" class="keyboard__item keyboard__item--other keyboard__item--space">Пробел</li>
            <li @click="removeChar" class="keyboard__item keyboard__item--other keyboard__item--backspace">Backspace</li>
          </ul>
        </div>
        <div class="main-name__buttons">
          <div class="button button--left">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--light">
                Назад
              </p>
            </div>
          </div>
          <div @click="regUser" class="button button--right">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Далее
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <footer class="footer">
      <div class="footer__container" />
    </footer>
  </main>
</template>

<script>
  import {apiClient} from "../../services/api";

  export default {
    name: "Name",

    data() {
      return {
        name: ''
      }
    },

    mounted() {
      window.addEventListener('click', e => {
        if (e.target.className === 'keyboard__item')
          this.addChar(e.target.innerText)
      });
    },

    methods: {
      regUser() {
        apiClient.post('/create-user-terminal', {
          name: this.name,
          firstname: this.name,
          phone: this.$store.getters.enteredPhone,
          face_id: this.$store.getters.faceId,
          category: this.$store.state.terminal.category
        })
          .then(res => {
            console.log('ok:', res)
            this.$store.commit('SET_CLIENT_BY_PHONE', res.data.data);
            //this.$router.push('/terminal/choose-services');
            this.$router.push('/terminal/search-unpaid-records');
            this.$store.commit('SET_CATEGORY', [])
          })
          .catch(res => {
            console.log('not ok: ', res)
          })
          .finally(res => {

          })
      },

      addChar(char) {
        if (this.name.length)
          char = char.toLowerCase()
        this.name += char.toString();
      },

      addSpace() {
        this.name += ' ';
      },

      removeChar() {
        const position = this.$refs.input_name.selectionStart
        if (!position)
          return
        let arr = this.name.split('')
        arr.splice(position - 1,1)
        this.name = arr.join('')
        this.$nextTick(() => {
          this.$refs.input_name.setSelectionRange(position, position - 1)
        });
      },
    }
  }
</script>

<style scoped>

</style>
