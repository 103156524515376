<template>
  <main class="main-phone">
    <div class="container main-phone__container">
      <h3 class="main-phone__title">
        Введите номер телефона
      </h3>
      <div class="phone-content">
        <div class="phone-content__left">
          <label
            class="my-phone my-phone__title"
          >
            Мой телефон
          </label>
          <!--          <input v-model="phone" id="phone__input" class="my-phone__input" type="text">-->
          <the-mask
            id="phone__input"
            v-model="phone"
            placeholder="+7"
            mask="+7 ### ### ## ##"
            class="my-phone__input"
          />
        </div>
        <div class="phone-content__right">
          <ul class="phone-numbers">
            <li
              class="phone-numbers__number"
              @click="addDigit(1)"
            >
              1
            </li>
            <li
              class="phone-numbers__number"
              @click="addDigit(2)"
            >
              2
            </li>
            <li
              class="phone-numbers__number"
              @click="addDigit(3)"
            >
              3
            </li>
            <li
              class="phone-numbers__number"
              @click="addDigit(4)"
            >
              4
            </li>
            <li
              class="phone-numbers__number"
              @click="addDigit(5)"
            >
              5
            </li>
            <li
              class="phone-numbers__number"
              @click="addDigit(6)"
            >
              6
            </li>
            <li
              class="phone-numbers__number"
              @click="addDigit(7)"
            >
              7
            </li>
            <li
              class="phone-numbers__number"
              @click="addDigit(8)"
            >
              8
            </li>
            <li
              class="phone-numbers__number"
              @click="addDigit(9)"
            >
              9
            </li>
            <li
              class="phone-numbers__number"
              @click="clear"
            >
              C
            </li>
            <li
              class="phone-numbers__number"
              @click="addDigit(0)"
            >
              0
            </li>
            <li
              class="phone-numbers__number phone-numbers__number--font"
              @click="removeDigit()"
            >
              &lt;
            </li>
          </ul>
          <div
            class="button button--right"
            @click="next"
          >
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Далее
              </p>
            </div>
          </div>
          <p class="agreement">
            Нажимая кнопку “Далее”, я выражаю согласие ООО “Стрижевский- розница” на обработку своих
            персональных данных ( имени, номера телефона, изображения) в соответствии с Федеральным законом от
            27.07.2006 №152-ФЗ “О персональных данных” для целей идентификации личности при получении услуг
            парикмахерской.
          </p>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="footer__container"/>
    </footer>
  </main>
</template>

<script>
import {apiClient} from "../../services/api";
import {TheMask} from "vue-the-mask";
import AppInput from "../../components/common/Input";
import AppButton from "../../components/common/Button";

export default {
  name: "Phone",

  watch: {
    phone(value) {
      if (value === '8' && !this.delIf8) {
        this.phone = ''
        this.delIf8 = true
      }
    }
  },

  components: {
    TheMask
  },

  data() {
    return {
      phone: '',
      delIf8: false
    }
  },

  methods: {
    next() {
      if (this.phone === '1230987654') {
        this.$router.push('/terminal/reports');
        return;
      }
      localStorage.setItem("phoneClient", JSON.stringify('+7' + this.phone) ?? '');
      apiClient.get('/users/search-by-phone?phone=' + this.phone + '&face_id=' + this.$store.getters.faceId).then(res => {
        if (res.data.success) {
          this.$store.commit('SET_CLIENT_BY_PHONE', res.data.data);
          this.$router.push('/terminal/search-unpaid-records');
          //this.$router.push('/terminal/choose-services');
        } else {
          this.notFound()
        }
      })
        .catch(res => {
          this.notFound()
        })
        .finally(res => {
          this.$store.commit('SET_ENTERED_PHONE', 7 + this.phone.toString());
        })
    },

    notFound() {
      this.$store.commit('SET_CLIENT_BY_PHONE', {id: 0, firstname: ''});
      if (this.$store.getters.loggedIn.department_data
        .use_category) {
        this.$router.push('/terminal/categories');
      } else { this.$router.push('/terminal/name') }
      console.log(this.$store.getters.loggedIn)
      // this.$router.push('/terminal/name');
    },

    addDigit(digit) {
      this.phone += digit.toString();
    },

    removeDigit() {
      this.phone = this.phone.substr(0, this.phone.length - 1);
      if (this.phone === '') {
        this.delIf8 = false
      }
    },

    clear() {
      this.phone = '';
      this.delIf8 = false
    }
  }
}
</script>

<style scoped>

</style>
