<template>
  <button
    v-bind="$attrs"
    class="button button--add"
    v-on="$listeners"
  >
    {{ title }}
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonAdd',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">

</style>
