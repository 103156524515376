import {apiClient} from "../../services/api";

export default {
  state: {
    clientByPhone: '',
    services: [],
    selectedServices: {},
    selectedMaster: [],
    totalPrice: 0,
    enteredPhone: '',
    faceId: '',
    record: {},
    unpaidRecords: [],
    selectedProducts: [],
    ccnetSocket: null,
    cashPaid: 0,
    receipt: [],
    categories: [],
    category: []
  },
  mutations: {
    SET_INFO_RECEIPT(state, receipt) {
      state.receipt = receipt
    },
    SET_CLIENT_BY_PHONE(state, client) {
      state.clientByPhone = client
    },
    SET_SERVICES(state, services) {
      state.services = services
    },
    SET_SELECTED_SERVICES(state, selected) {
      state.selectedServices = selected
    },
    SET_SELECTED_MASTER(state, selectedMaster) {
      state.selectedMaster = selectedMaster
    },
    SET_TOTAL_PRICE(state, totalPrice) {
      state.totalPrice = totalPrice
    },
    SET_ENTERED_PHONE(state, enteredPhone) {
      state.enteredPhone = enteredPhone
    },
    SET_FACE_ID(state, faceId) {
      state.faceId = faceId
    },
    SET_RECORD(state, record) {
      state.record = record
    },
    SET_UNPAID_RECORDS(state, records) {
      state.unpaidRecords = records
    },
    SET_SELECTED_PRODUCTS(state, products) {
      state.selectedProducts = products
    },
    SET_CCNET_SOCKET(state, socket) {
      state.ccnetSocket = socket
    },
    SET_CASH_PAID(state, paid) {
      state.cashPaid = paid
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories.data
    },
    SET_CATEGORY(state, categoriy) {
      state.category = categoriy
    }
  },
  actions: {
    getCategories({commit}) {
      apiClient.get('/yc-categories').then(res => {
        console.log(res.data)
        commit('SET_CATEGORIES', res.data)
      })
    },
    getServices({commit, state}) {
      const staffId = state.selectedMaster.id ? state.selectedMaster.id : state.selectedMaster.staff_id
      apiClient.get('/services-yc?staff_id=' + (staffId ?? '')).then(res => {
        commit('SET_SERVICES', res.data.services)
      })
    }
  },
  getters: {
    clientByPhone(state) {
      return state.clientByPhone
    },
    services(state) {
      return state.services
    },
    selectedServices(state) {
      return state.selectedServices
    },
    selectedMaster(state) {
      return state.selectedMaster
    },
    totalPrice(state) {
      return state.totalPrice
    },
    enteredPhone(state) {
      return state.enteredPhone
    },
    faceId(state) {
      return state.faceId
    },
    record(state) {
      return state.record
    },
    unpaidRecords(state) {
      return state.unpaidRecords
    },
    selectedProducts(state) {
      return state.selectedProducts
    },
    ccnetSocket(state) {
      return state.ccnetSocket
    },
    cashPaid(state) {
      return state.cashPaid
    },
    receipt(state) {
      return state.receipt
    },
  }
}
