<template>
  <app-checkbox
    v-if="!checked"
    :class="{'nav-staff__btn--color':true,'nav-staff__btn--color':true,'checked':checked}"
    @input="getAccess()"
  >
    Доступ в BIS
  </app-checkbox>
  <span v-else>Настройки аккаунта</span>
</template>

<script>

import AppCheckbox from "./Checkbox";

export default {
  name: "CheckBoxToBIS",
  components: {
    AppCheckbox
  },
  props: {
    person: {
      type: Object,
      default: Object
    }
  },
  data() {
    return {
      checked: false,
    }
  },
  watch: {
    person(newPerson) {
      this.checkToBis(newPerson)
    }
  },
  mounted() {
    this.checkToBis(this.person)
  },
  methods: {
    checkToBis(person) {
      if (person && person.role) {
        this.checked = person.email !== '' && person.role.length !== 0;
      }
    },
    getAccess() {
      setTimeout(() => {
        this.checked = true
      }, 300)
    }
  }
}
</script>

<style scoped>

</style>
