import Vue from "vue";
import editSVG from '../../assets/images/ListsStaff/edit.svg'
import deleteSVG from '../../assets/images/ListsStaff/delete.svg'
export default Vue.extend({
  data: function () {
    return {
      idIvideon:null
    };
  },
  beforeMount() {
  },
  mounted() {
  },
  created() {
    this.idIvideon=this.params.value
  },
  methods: {
    showPerson(idIvideon) {
      this.$router.push(`/lists/staff/ivideon/${idIvideon}`)
    }
  },
  template: `
    <div style="display: flex; margin-left: 40px;margin-top: 10px" >
    <button v-on:click="showPerson(idIvideon)" class="edit-person"><img src="${editSVG}" alt="edit"></button>
    <button v-on:click="editPerson($event)" class="edit-person"><img src="${deleteSVG}" alt="delete"></button>
    </div>
  `
});
