<template>
  <main class="main-choice">
    <div class="container main-choice__container">
      <h1 class="main-choice__title title-bg">
        Выберите время записи
      </h1>
      <div class="main-choice__content choice-time">

        <div style="padding-top:111px;display: flex;justify-content: center" v-if="masters.length && freeTime">
          <VueSlickCarousel v-bind="settings">
            <div
              v-for="(master, idx) in masters"
              :key="idx"
              class="choice-time__card"
              @click="choose(master)"
            >
              <div class="frame">
                <img
                  class="master-photo"
                  :src="master.avatar"
                  alt="master-photo"
                >
              </div>
              <div class="choice-time__master">
                мастер {{ master.name.split(' ')[0] }}
              </div>
              <div
                class="choice-time__body"
                :class="{'selected-master-body': isSelected(master.staff_id)}"
              >
                <p
                  class="choice-time__date"
                  :class="{'selected-master-time': isSelected(master.staff_id)}"
                >
                  {{
                    master.date
                  }}
                </p>
                <p
                  class="choice-time__time"
                  :class="{'selected-master-time': isSelected(master.staff_id)}"
                >
                  {{
                    master.time
                  }}
                </p>
              </div>
            </div>
          </VueSlickCarousel>
        </div>

        <div
          v-if="!masters.length && freeTime"
          class="loading"
        >
          Загрузка...
        </div>
        <div
          v-if="!freeTime"
          class="loading"
        >
          Нет свободного времени на сегодня.
        </div>

        <div
          class="button button--right main-choice__buttons"
          :class="{'hide-button': !selected.datetime}"
          @click="makeRecord"
          style="position: absolute; top: 40px; left: 0;"
        >
          <div class="button__inner">
            <p class="button__inner-title button__inner-title--black">
              Записать
            </p>
          </div>
        </div>
      </div>
    </div>
     <footer class="footer">
      <div class="footer__container"/>
    </footer>
  </main>
</template>

<script>
import {apiClient} from "../../services/api";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "ChooseMaster",

  data() {
    return {
      settings: {
          "focusOnSelect": false,
          "touchThreshold": 4,
          "infinite": false,
          "speed": 501,
          "slidesToShow":3,
          "slidesToScroll": 2,
          "variableWidth": true
      },
      masters: [],
      selected: {},
      freeTime: true
    }
  },
  watch: {
    masters(v) {
      console.log(v)
    }
  },
  components: {
    VueSlickCarousel
  },
  computed: {
    currentClient() {
      return this.$store.getters.clientByPhone;
    },
    selectedServices() {
      return this.$store.getters.selectedServices;
    },
    oneService() {
      return Object.keys(this.selectedServices)[0];
    },
    fullSeanceLength() {
      let total = 0;
      for (let id in this.selectedServices) {
        total += this.selectedServices[id].seance_length;
      }
      return total;
    }
  },

  mounted() {
    console.log(this.$store.getters.selectedServices)
        let queryString = ''
        let first = true
        for (let key in this.selectedServices) {
          queryString += (first ? '?' : '&') + 'service_ids[]=' + key
          first = false
        }
        apiClient.get('/services-select-yc' + queryString).then(res => {
          this.masters = res.data.servicesDate
          if (!this.masters.length)
            this.freeTime = false
        })
  },

  methods: {
    choose(master) {
      this.selected = master
      this.$store.commit('SET_SELECTED_MASTER', master)
    },

    isSelected(id) {
      return this.selected.staff_id === id || false
    },

    makeRecord() {
      if (!this.selected.datetime)
        return

      const data = {
        staff_id: this.selected.staff_id,
        services: this.selectedServices,
        client: {
          phone: this.currentClient.phone,
          name: this.currentClient.firstname + this.lastname(this.currentClient.lastname)
        },
        datetime: this.selected.datetime,
        seance_length: this.selected.seance_length
      }
      // console.log(JSON.stringify(data, null, ' '))
      apiClient.post('/add-record-yc', data).then(res => {
        console.log(res)
        if (!res.data.success) {
          console.log('Record create error.')
          return
        }
        this.$store.commit('SET_RECORD', res.data.data)
        // this.$router.push('/terminal/send-receipt')
        this.$router.push('/terminal/choose-pay-method')
        // this.$router.push('/terminal/choose-pay-method')
      })
    },

    lastname(lastname) {
      if (!lastname || lastname === 'null' || lastname === null)
        return '';
      return ' ' + lastname;
    }
  },

}
</script>

<style lang="scss">

.slick-slider{
  max-width: 923px;
}
.slick-slide {
  width: auto !important;
}

.slick-prev {
  left: -56px;

  &:before {
    color: #4fd8db;
  }
}

.slick-next {
  right: -26px;

  &:before {
    color: #4fd8db;
  }
}

.slick-prev:before, .slick-next:before {
  font-size: 55px;
}

.selected-master-body {
  background-image: url("../../assets/images/terminal/chooseMasterBackCard.svg") !important;
}

.selected-master-time {
  color: #17324d;
}

.loading {
  position: absolute;
  color: #0dc5c1;
  font-size: 40px;
  margin-left: 100px;
  margin-top: 100px;
}

.hide-button {
  visibility: hidden;
}

</style>
