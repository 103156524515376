<template>
  <section class="edit-personnel edit-personnel__container">
    <form
      action=""
    >
      <h3 class="edit-personnel__title">
        Точка
      </h3>
      <multiselect
        v-model="event.department"
        class="select"
        :options="departments"
        label="department_name"
        track-by="department_id"
        :searchable="false"
        placeholder="Выберите точку"
        :taggable="true"
      />
      <div v-if="personsYClients.length > 0">
        <h3 class="edit-personnel__title">
          Пользователь в yClients
        </h3>
        <multiselect
          v-model="event.yc_company_id"
          :allow-empty="false"
          class="select select-yClients"
          :options="personsYClients"
          label="yc_name"
          track-by="yc_staff_id"
          placeholder="Выберите или введите имя"
          :taggable="true"
        >
          <template
            slot="singleLabel"
            ref="singleLabel"
            slot-scope="props"
          >
            <img
              class="option__image"
              :src="props.option.avatar"
            >
            <span class="option__desc">
              <span
                class="option__title"
              >{{ props.option.yc_name }}
              </span>
              <span class="option__small">{{ props.option.specialization }}</span>
            </span>
          </template>
          <template
            slot="option"
            slot-scope="props"
          >
            <img
              class="option__image"
              :src="props.option.avatar"
            >
            <div class="option__desc">
              <span class="option__title">{{ props.option.yc_name }}</span>
              <span class="option__small">{{ props.option.specialization }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <slot
        name="save"
      />
    </form>
  </section>
</template>

<script>


export default {
  name: "EditPersonnel",
  components: {},
  props: {
    person: {
      type: Object,
      default: Object
    },
    newStaff: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      event: {
        department: '',
        yc_company_id: ''
      }
    }
  },
  computed: {
    personsYClients() {
      return this.$store.state.persons.personsYClients
    },
    departments() {
      return this.$store.state.departments.departments
    },
    personDepartments() {
      return this.person.department
    },
  },
  watch: {
    'event.department': {
      async handler(newVal) {
        this.getPersonsByYClients(newVal.yc_company_id)
        this.$emit('submit', {department_id: newVal.department_id})
      },
      deep: true
    },
    'event.yc_company_id': {
      handler(newVal) {
        this.$emit('submit', {yc_staff_id: newVal.yc_staff_id, yc_name: newVal.yc_name})
      },
      deep: true
    }
  },
  created() {
    this.$store.dispatch('fetchDepartments')
  },
  beforeMount() {
    if (this.personDepartments && this.personDepartments.length > 0) {
      this.event.department = this.personDepartments[0]
    }
    if (this.person.yc && this.person.yc.length > 0) {
      this.event.yc_company_id = this.person.yc[0]
    }
  },
  beforeDestroy() {
    this.departments.length = 0
    this.personsYClients.length = 0
  },
  methods: {
    getPersonsByYClients(ycId) {
      this.$store.dispatch('getPersonsByYClients', ycId)
    },
  }
}
</script>

<style scoped>

</style>
