import Vue from 'vue'
import Vuex from 'vuex'
import persons from './modules/persons.js'
import departments from './modules/departments.js'
import * as notification from './modules/notification'
import clients from "./modules/clients";
import authorization from "./modules/authorization";
import role from "./modules/role";
import callCenter from "./modules/callCenter";
import terminal from "./modules/terminal";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layout: 'default-layout'
  },
  mutations: {
    setLayout(state, payload) {
      state.layout = payload
    }
  },
  actions: {},
  modules: {
    persons,
    role,
    departments,
    clients,
    notification,
    authorization,
    callCenter,
    terminal
  },
  getters: {
    layout(state) {
      return state.layout
    }
  }
})
