<template>
  <main class="main-name">
    <div class="container main-name__container">
      <div class=" main-content-bg">
        <h3 class="main-name__title title-bg">
          Введите вашу почту
        </h3>

        <div class="name-content">
          <input v-model="editEmail" class="name-content__input input-value" type="text" ref="input_email">
          <p v-if="errors.email" class="valid__email">
            {{errors.email}}
          </p>
          <ul class="keyboard keyboard__line">
            <li class="keyboard__item">1</li>
            <li class="keyboard__item">2</li>
            <li class="keyboard__item">3</li>
            <li class="keyboard__item">4</li>
            <li class="keyboard__item">5</li>
            <li class="keyboard__item">6</li>
            <li class="keyboard__item">7</li>
            <li class="keyboard__item">8</li>
            <li class="keyboard__item">9</li>
            <li class="keyboard__item">0</li>

            <li class="keyboard__item">-</li>
            <li class="keyboard__item">=</li>
            <li class="keyboard__item">+</li>
            <li class="keyboard__item">*</li>



            <li @click="removeChar" class="keyboard__item keyboard__item--other keyboard__item--backspace"></li>
          </ul>
          <ul class="keyboard keyboard__line">
            <li class="keyboard__item">q</li>
            <li class="keyboard__item">w</li>
            <li class="keyboard__item">e</li>
            <li class="keyboard__item">r</li>
            <li class="keyboard__item">t</li>
            <li class="keyboard__item">y</li>
            <li class="keyboard__item">u</li>
            <li class="keyboard__item">i</li>
            <li class="keyboard__item">o</li>
            <li class="keyboard__item">p</li>
            <li class="keyboard__item">?</li>
            <li class="keyboard__item">^</li>
            <li class="keyboard__item">_</li>
            <li class="keyboard__item">/</li>
            <li class="keyboard__item">?</li>


          </ul>

          <ul class="keyboard keyboard__line">
            <li class="keyboard__item">a</li>
            <li class="keyboard__item">s</li>
            <li class="keyboard__item">d</li>
            <li class="keyboard__item">f</li>
            <li class="keyboard__item">g</li>
            <li class="keyboard__item">h</li>
            <li class="keyboard__item">j</li>
            <li class="keyboard__item">k</li>
            <li class="keyboard__item">l</li>
            <li class="keyboard__item">.</li>
            <li class="keyboard__item">!</li>
            <li class="keyboard__item">#</li>
            <li class="keyboard__item">$</li>
            <li class="keyboard__item">%</li>
            <li class="keyboard__item">&</li>


          </ul>
          <ul class="keyboard keyboard__line">
            <li class="keyboard__item">@</li>
            <li class="keyboard__item">z</li>
            <li class="keyboard__item">x</li>
            <li class="keyboard__item">c</li>
            <li class="keyboard__item">v</li>
            <li class="keyboard__item">b</li>
            <li class="keyboard__item">n</li>
            <li class="keyboard__item">m</li>
            <li class="keyboard__item">{</li>
            <li class="keyboard__item">|</li>
            <li class="keyboard__item">}</li>
            <li class="keyboard__item">~</li>
            <li class="keyboard__item">'</li>
            <li class="keyboard__item">`</li>


          </ul>
<!--          <ul class="keyboard keyboard__line">-->
<!--           -->
<!--          </ul>-->
        </div>
        <div class="main-name__buttons">
          <div @click="cancel" class="button button--left">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--light">
                Отмена
              </p>
            </div>
          </div>
          <div @click="next" class="button button--right">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Далее
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <footer class="footer">
      <div class="footer__container" />
    </footer>
  </main>

</template>

<script>
export default {
name: 'EditEmail',
  data () {
  return {
    editEmail: JSON.parse(localStorage.getItem("emailClient")) ?? this.$store.getters.clientByPhone.email  ?? '',
    errors: {
      email: null
    }
  }
  },
  mounted() {
    window.addEventListener('click', e => {
      if (e.target.className === 'keyboard__item')
        this.addChar(e.target.innerText)
    });
  },
  methods: {
  cancel () {
    this.$router.push('/terminal/send-receipt')
  },
  next() {
    if (this.editEmail) {
      if (!this.isEmailValid(this.editEmail)) {
        this.errors.email = 'email имеет неверный формат';
        return
      }
      localStorage.setItem("emailClient", JSON.stringify(this.editEmail) ?? '');
    }
    this.$router.push('/terminal/send-receipt')
  },
    addChar(char) {
      char = char.toLowerCase()
      this.editEmail += char.toString();
    },
    isEmailValid () {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.editEmail);
    },

    removeChar() {
      const position = this.$refs.input_email.selectionStart
      if (!position)
        return
      let arr = this.editEmail.split('')
      arr.splice(position - 1,1)
      this.editEmail = arr.join('')
      this.$nextTick(() => {
        this.$refs.input_email.setSelectionRange(position, position - 1)
      });
    }
  }
}
</script>

<style scoped lang="scss">
.valid__email {
  font-family: Ki-Regular, serif;
  color: red;
  font-size: 25px;
  margin-top: -35px;
  margin-bottom: 10px;
}
.keyboard__item--backspace {
  width: 60px;
  height: 60px;
  padding-left: 26px;
  //background-image: url(../images/dest/name_arrow.svg);
  background-position: 11px 25px;
  background-repeat: no-repeat
}
</style>
