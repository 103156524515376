<template>
  <main class="payment-main">
    <div class="container payment-main__container">
      <h3 class="payment-main__title title-bg">
        Оплата наличными
      </h3>
      <div class="main-content-bg">
        <div class="payment-main__content">
          <h3 class="payment-main__make-title">
            Необходимо внести: {{ totalPrice }} р
          </h3>
          <p class="payment-main__make-subtitle">
            Внесите деньги по одной купюре
          </p>
          <h2 class="payment-main__introduced">Внесено:</h2>
          <input disabled class="name-content__input input-value" :placeholder="paid + ' р'" type="text">
          <img style="visibility: hidden" class="notification notification__icon" :src="require('../../assets/terminal/images/dest/icon_info.png')" alt="icon_info">
          <!--          <p style="" class="notification__title">Внимание!</p>-->
          <p style="margin-bottom: 32px; font-size: 40px;" class="notification__title">Этот терминал сдачу не выдаёт</p>
        </div>
        <div v-if="noPaper" v-html="noPaper" class="payment-card__title" style="position: absolute; top: 620px; left: 230px;"></div>
        <div class="main-services__buttons">
          <div @click="$router.go(-1)" class="button button--left">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--light">
                Назад
              </p>
            </div>
          </div>
          <div v-if="!inProgress && !errors && paid >= totalPrice && totalPrice > 0" @click="next" class="button button--right">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Далее
              </p>
            </div>
          </div>
          <div v-if="inProgress" class="button button--right button--light-blue--right">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Обработка...
              </p>
            </div>
          </div>
          <div v-if="errors" class="button button--right button--light-blue--right">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Ошибка
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import {apiClient} from "../../services/api";
  import department from "../../components/mixins/department";

  export default {
    name: "PayByCash_",

    mixins: [department],

    props: {
      totalPrice: {
        default: 0
      },
      errorPay: {
        default: false
      },
      noPaper: {
        default: false
      },
    },

    watch: {
      errorPay(error) {
        if (error) {
          this.inProgress = false
          this.errors = true
        }
      }
    },

    data() {
      return {
        inProgress: false,
        errors: false,
        startPay: false,
        ccnetInterval : null,
      }
    },

    computed: {
      paid: {
        get() {
          return this.$store.getters.cashPaid;
        },
        set(value) {
          this.$store.commit('SET_CASH_PAID', value);
        }
      },

      socket() {
        return this.$store.getters.ccnetSocket;
      }
    },

    methods: {
      next() {
        this.inProgress = true
        this.$emit('pay', true);
      },
    },

    mounted() {
      if (this.paid < this.totalPrice)
        this.startPay = true;

      let vm = this;

      if (!this.$store.getters.ccnetSocket || this.$store.getters.ccnetSocket.readyState !== 1) {
        this.$store.commit('SET_CCNET_SOCKET', new WebSocket("ws://127.0.0.1:5000/"));
        this.$store.getters.ccnetSocket.onopen = function() {
          vm.$store.getters.ccnetSocket.send(JSON.stringify({
            method: 'connect',
            data: {
              port: vm.cashPort
            }
          }));
        }
      }

      this.socket.onmessage = function (event) {
        let socketData = JSON.parse(event.data);
        console.log(socketData)

        if (socketData.method === 'escrow') {
          vm.socket.send(JSON.stringify({
            'method': 'stack',
            'data': {}
          }));
        } else if(socketData.method === 'stacked'){
          vm.paid += socketData.bill.amount;

          if (vm.paid >= vm.totalPrice) {
            console.log('closing cashcode');
            vm.startPay = false;
            vm.socket.send(JSON.stringify({
              'method': 'close',
              'data': {}
            }));
          }
        } else if (socketData.method === 'returned') {
          vm.paid -= socketData.bill.amount;
        }
      }

      this.ccnetInterval = setInterval(() => {
        if (!this.startPay || this.socket.readyState !== 1)
          return;
        this.socket.send(JSON.stringify({
          'method': 'begin',
          'data': {}
        }));
      }, 3000)
    },

    beforeDestroy() {
      clearInterval(this.ccnetInterval);
      this.socket.send(JSON.stringify({
        'method': 'close',
        'data': {}
      }));
    }
  }
</script>

<style scoped>

</style>
