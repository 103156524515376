<template>
  <main class="main-phone">
    <div class="container main-phone__container">
      <h3 class="main-phone__title">
        Меню
      </h3>
      <div class="phone-content">
        <div class="phone-content__left">
          
          <div style="margin-top: 50px; color: #4fd8db; font-size: 30px;"></div>
        </div>
        <div class="phone-content__right" style="visibility: visible; margin-left: 300px;">
         <div style="height: 700px;"></div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: "Menu",

    data() {
      return {
        
      }
    },

    methods: {
      
    }
  }
</script>

<style scoped>

</style>
