<template>
  <div class="edit-ToBIS">
    <form
      action=""
      @submit.prevent.stop.self="$emit('submit',getFormData())"
    >
      <div class="about-staff edit-ivideon__about-staff">
        <div class="about-staff__column edit-ivideon__column">
          <div class="about-staff__left edit-ivideon__left">
            <app-input
              v-model="event.email"
              label="Email"
              type="email"
              class-label="edit-staff__item focus-input"
              class-input="create-staff__value create-staff__value--email"
            />
            <app-input
              id="password"
              v-model="event.password"
              class-label="edit-staff__item focus-input"
              :type="type"
              label="Пароль"
              class-input="create-staff__value create-staff__value--password"
            >
              <button
                :class="{'edit-ToBIS__input':true,
                         'edit-ToBIS__input--password':true,
                         'edit-ToBIS__input--text':type==='text'}"
                @click.prevent="showPassword"
              />
            </app-input>
          </div>

          <div class="about-staff__right">
            <h3 class="edit-personnel__title">
              Роль
            </h3>
            <multiselect
              v-model="event.role"
              class="select select__ToBIS"
              :options="role.role"
              label="name"
              track-by="id"
              :searchable="false"
              :close-on-select="false"
              placeholder="Выберите роль"
              :show-labels="false"
              :multiple="true"
              :taggable="true"
              :limit="2"
            />
          </div>
        </div>
        <div class="staff-buttons">
          <app-button
            v-if="!newStaff"
            class="button button--disabled"
            @click.prevent.stop.exact.native="blockAccess"
          >
            Заблокировать вход
          </app-button>
          <slot name="save" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import AppInput from "../common/Input";
import AppButton from "../common/Button";
import Multiselect from "vue-multiselect";
import {mapState} from "vuex";

export default {
  name: "EditToBIS",
  components: {
    AppInput,
    AppButton,
    Multiselect
  },
  props: {
    person: {
      type: Object,
      default: Object
    },
    newStaff: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: 'password',
      event: {
        password: '',
        email: '',
        role: []
      },
      error: null
    }
  },
  computed: {
    ...mapState(['role']),
    email() {
      return this.person.email
    },
  },
  watch: {
    email(oldEmail) {
      if (oldEmail) {
        this.event.email = oldEmail
      }
    },
    'event': {
      handler(newVal) {
        this.$emit('submit', newVal)
      },
      deep: true
    },

  },
  created() {
    this.$store.dispatch('getAllRole')

  },
  mounted() {
    if (this.person.role && this.person.role.length !== 0) {
      this.person.role.forEach(role => this.event.role.push(role))
    }
  },
  methods: {
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text'
      } else {
        this.type = 'password'
      }
    },
    blockAccess() {
      this.$store.dispatch('blockAccess', this.person)
    },
  }
}
</script>

<style scoped>

</style>
