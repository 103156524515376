<template>
  <section>
    <router-link
      class="back-to-lists"
      to="/lists/staff/ivideon"
    >
      Назад к списку
    </router-link>
    <form
      class="create-staff"
      action=""
      @submit.prevent.stop.self.exact="onSubmit"
    >
      <div class="photos-list">
        <div
          v-for="photo in personIdIvideon.photos"
          :key="photo"
          class="hover-photo create-staff__photos photos-list__item  hover-icon"
        >
          <img
            class="photos-list__img"
            :src="photo"
          >
        </div>
      </div>

      <div class="about-staff">
        <div class="about-staff__column edit-ivideon__column">
          <div class="about-staff__left">
            <app-input
              v-model.trim="event.surname"
              label="Фамилия"
              class-label="create-staff__item focus-input"
              class-input="create-staff__value"
              :class="{error:$v.event.surname.$invalid}"
            />
            <app-input
              v-model.trim="event.name"
              label="Имя"
              class-label="create-staff__item focus-input"
              class-input="create-staff__value"
              :class="{error:$v.event.name.$invalid}"
            />
            <app-input
              v-model.trim="event.patronymic"
              label="Отчество"
              class-label="create-staff__item focus-input"
              class-input="create-staff__value"
              :class="{error:$v.event.patronymic.$invalid}"
            />
            <label
              for="phone"
              class="edit-staff__item focus-input"
            >
              Телефон
              <the-mask
                id="phone"
                v-model="event.phone"
                class="create-staff__value"
                mask="+7 ### ### ## ##"
              />
            </label>
          </div>
          <div class="about-staff__right">
            <h3 class="edit-personnel__title">
              Точка
            </h3>
            <multiselect
              v-model="event.departments"
              class="select"
              :options="departments"
              label="department_name"
              track-by="department_id"
              :value="event.departments"
              :searchable="false"
              placeholder="Выберите точку"
            />
          </div>
        </div>
      </div>
      <div class="ivideon-info">
        <p class="ivideon-info__item">
          ФИО из Ivideon:<span class="ivideon-info__item-data">{{ personIdIvideon.name }}</span>
        </p>
        <p class="ivideon-info__item">
          Описание из Ivideon:<span class="ivideon-info__item-data">{{ personIdIvideon.person }}</span>
        </p>
      </div>
      <div class="staff-buttons">
        <app-button
          class="button button--disabled"
        >
          Удалить персону
        </app-button>

        <app-button
          class="button button--disabled"
          @click.prevent.stop.self="backLists"
        >
          Отменить
        </app-button>
        <app-button
          class="button"
        >
          Сохранить
        </app-button>
      </div>
    </form>
  </section>
</template>

<script>

import AppInput from "../components/common/Input";
import AppButton from "../components/common/Button";
import {helpers} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import {TheMask} from "vue-the-mask";

const alpha = helpers.regex('alphaNumAndDot', /^([a-zа-яё 0-9]+|\d+)$/i);

export default {
  name: 'CreateStaffIvideon',
  components: {
    AppInput,
    AppButton,
    Multiselect,
    TheMask,
  },
  data() {
    return {
      personIdIvideon: '',
      event: {
        departments: '',
        phone: '',
        name: '',
        surname: '',
        patronymic: '',
      }
    }
  },
  computed: {
    personByIdIvideon() {
      const person = this.$store.state.persons.personIdIvideon
      console.log(person)
      if (person) person.photos.splice(4)
      return person
    },
    departments() {
      const {departments} = this.$store.state.departments
      return departments
    }
  },
  watch: {
    personByIdIvideon(person) {
      this.personIdIvideon = person
    }
  },
  mounted() {
    console.log(this.props)
  },
  created() {
    this.$store.dispatch('getPersonIvideonById', this.$route.params.id)
    this.$store.dispatch('fetchDepartments')
  },
  validations: {
    event: {
      surname: {
        alpha
      },
      name: {
        alpha,
      },
      patronymic: {
        alpha
      }
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.event.$invalid) {
        const formData = this.getFormData()
        this.sendFormData(formData)
      } else {

      }

    },
    async sendFormData(formData) {
      const create = await this.$store.dispatch("createPersonIvideon", formData)
      if (create) await this.$router.push('/lists/staff')
    },
    getFormData() {
      return {
        person_ivideon_id: this.personIdIvideon.ivideon_id,
        department_id: this.event.departments.department_id,
        name: this.event.name,
        surname: this.event.surname,
        patronymic: this.event.patronymic,
        phone: '7' + this.event.phone,
        photo: this.personIdIvideon.photos[0],
        face_gallery_id: this.personIdIvideon.face_gallery_id
      }
    },
    backLists() {
      this.$router.push('/lists/staff/ivideon')
    }
  }
}
</script>

<style lang="scss">


</style>
