import {apiClient} from '../../services/api'

export default {
  state: {
    departments: []
  },
  mutations: {
    SET_DEPARTMENTS(state, departments) {
      state.departments = departments
    }
  },
  actions: {
    async fetchDepartments({commit, dispatch}) {
      try {
        const {data: {data}} = await apiClient.get('/departments')
        console.log(data)
        commit('SET_DEPARTMENTS', data)
      } catch (error) {
        const notification = {
          type: 'error',
          message: `fetchDepartments have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    }
  },
  getters: {}
}

