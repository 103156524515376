<template>
  <section class="schedule">
    <main class="schedule__article">
      <div class="wrapper-header schedule__top">
        <input
          id="filter-text-box"
          v-model="filterText"
          class="ag-theme-balham-search"
          type="text"
          placeholder="Поиск"
          @input="onFilterTextBoxChanged()"
        >
        <app-pagination />
      </div>
      <ag-grid-vue
        style="width: 100%; height: 85vh;"
        :get-row-style="getRowStyle"
        :grid-options="gridOptions"
        :row-data="rowData"
        class="ag-theme-balham"
        :column-defs="columnDefs"
        :row-heigh="rowHeight"
        :default-col-def="defaultColDef"
        :framework-components="frameworkComponents"
        :get-row-height="getRowHeight"
        :animate-rows="true"
        @column-resized="onColumnResized"
        @grid-ready="onGridReady"
        @column-visible="onColumnVisible"
      />
    </main>
  </section>
</template>

<script>
import AppPagination from "../components/Pagination";
import {AgGridVue} from "ag-grid-vue";


export default {
  name: 'Schedule',
  components: {
    AppPagination,
    AgGridVue
  },
  data() {
    return {
      routeTitle: 'Расписание',
      styleNavMargin: 223,
      gridOptions: null,
      filterText: '',
      columnDefs: null,
      rowData: null,
      rowHeight: null,
      frameworkComponents: null,
      getRowStyle: null,
      showListIvieon: false,
      defaultColDef: null
    }
  },
  computed: {
    departments() {
      return this.$store.state.departments.departments
    }
  },
  watch: {
    departments(departments) {
      this.rowData = departments
    }
  },
  created() {
    this.$store.dispatch('fetchDepartments')
  },
  async beforeMount() {
    this.gridOptions = {
      onCellDoubleClicked: (e)=> {
        console.log(e)
        this.$router.push('/department/' + e.data.department_id)
      },
    }
    this.columnDefs = [
      {
        headerName: 'Название салона',
        field: 'department_name',
        lockPosition: true,
        width: 330
      },
      {
        headerName: 'Адрес',
        field: 'department_address',
        width: 257,
        lockPosition: true
      },
      {
        headerName: 'Открытие',
        field: 'time_begin',
        width: 261,
        lockPosition: true
      },
      {
        headerName: 'Закрытие',
        field: 'time_end',
        width: 256,
        lockPosition: true
      },
      {
        headerName: 'id_точки',
        field: 'department_id',
        width: 301,
        lockPosition: true,
      }
    ]
    this.defaultColDef = {
      flex: 1
    }
    this.getRowStyle = params => {
      if (params.node.rowIndex % 2 === 0) {
        return {
          background: '#FDFDFD',
          width: '100%',
          border: '1px solid #FBFBFB',
          borderRadius: '3px'
        }
      }
    }
    this.rowData = []
  },
  methods: {
    getRowHeight(params) {
      return params.node.rowHeight = 50;
    },
    resize() {
      setTimeout(() => {
        this.gridApi.resetRowHeights();
      }, 100);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.resize()
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.filterText)
    },
    onColumnResized(params) {
      params.api.resetRowHeights()
    },
    onColumnVisible(params) {
      params.api.resetRowHeights()
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule {
  display: flex;
  width: 100%;
}

.schedule__article {
  width: 100%;
}

</style>
