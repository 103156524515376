<template>
  <main class="paid-main">
    <div class="container paid-main__container">
<!--      <h1 class="paid-main__title">-->
<!--        {{ currentClient.firstname }}, спасибо!-->
<!--      </h1>-->
      <h3 class="container" style="text-align: left; font-size: 40px; color: #FFFFFF; line-height: 50px; margin-top: -50px;">
        Понравилось у нас?<br>
        Хочешь открыть свой филиал Стрижевского?<br>
        Вложения от 2,5 млн. руб.<br>
        Средняя выручка 600 тыс. руб.<br>
        Возврат инвестиций от 9 мес.<br>
        Узнай подробности о франшизе, нажми ОК и мы отправим тебе презентацию на номер
        <the-mask
          id="phone__input"
          v-model="phone"
          placeholder="+7"
          mask="+7 ### ### ## ##"
          class="my-phone__input"
          style="margin-left: 290px;"
        />
        <div
          class="button button--right"
          style="margin-left: 734px; margin-top: 50px;"
          @click="sendSms"
        >
          <div class="button__inner">
            <p class="button__inner-title button__inner-title--black" style="margin-left: 10px;">
              OK
            </p>
          </div>
        </div>
        <div
          class="button button--right"
          style="margin-left: 0px; margin-top: -112px;"
          @click="$router.push('/terminal/home')"
        >
          <div class="button__inner">
            <p class="button__inner-title button__inner-title--black" style="margin-left: 10px;">
              Закрыть
            </p>
          </div>
        </div>
      </h3>
<!--      <input-->
<!--        class="paid-main__value input-value"-->
<!--        :placeholder="current + ' сек.'"-->
<!--        type="text"-->
<!--      >-->
    </div>
    <div style="visibility: hidden;" class="questions">
      <h3 class="questions__title">
        Как вы узнали о нас?
      </h3>
      <div class="buttons questions__buttons">
        <div class="questions__button">
          <div class="buttons__button button button--right">
            <div class="button__inner paid-main__button">
              <p class="button__inner-title questions__button-title">
                Реклама VK
              </p>
            </div>
          </div>
          <div class="buttons__button button button--right">
            <div class="button__inner paid-main__button">
              <p class="button__inner-title questions__button-title">
                Реклама Instagram
              </p>
            </div>
          </div>
        </div>
        <div class="questions__button">
          <div class="buttons__button button button--right">
            <div class="button__inner paid-main__button">
              <p class="button__inner-title questions__button-title">
                Проходил мимо
              </p>
            </div>
          </div>
          <div class="buttons__button button button--right">
            <div class="button__inner paid-main__button">
              <p class="button__inner-title questions__button-title">
                Друзья посоветовали
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import {apiClient} from "@/services/api";
  import {TheMask} from "vue-the-mask";

  export default {
    name: "Done",

    components: {
      TheMask
    },

    data() {
      return {
        interval: null,
        current: 60,
        phone: this.$store.getters.enteredPhone
      }
    },

    computed: {
      currentClient() {
        return this.$store.getters.clientByPhone;
      }
    },

    mounted() {
      this.interval = setInterval(() => {
        this.timerCount();
      }, 1000);
    },

    methods: {
      timerCount() {
        this.current--;
        if (this.current < 1)
          location.href = '/terminal/home'
      },
      sendSms() {
        apiClient.post('/terminal/send-sms-strizh', { phone: ('7' + this.phone) }).then(res => {
          console.log("send sms: ", res.data?.status)
        })
        this.$router.push('/terminal/home')
      }
    },

  }
</script>

<style scoped>

</style>
