<template>
  <main class="pay-method">
    <div class="container pay-method__container">
      <h3 class="pay-method__title title-bg">
        Выберите способ оплаты
      </h3>
      <div class="pay-method__content main-content-bg">
        <div class="pay-method__block">
          <div class="pay-method__master">
            <div class="frame pay-method__frame">
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
            </div>
          </div>
          <div class="pay-method__info info-pay">
            <h3 class="info-pay__date">

            </h3>
            <p class="info-pay__service">

            </p>
            <p class="info-pay__service">

            </p>
          </div>
        </div>
        <div class="pay-method__icons">
          <img
            :src="require('../../assets/terminal/images/dest/pay-method-nal.svg')"
            class="pay-method__cash"
            @click="payByCash"
          >
          <img
            :src="require('../../assets/terminal/images/dest/pay-method-card.svg')"
            class="pay-method__card"
            @click="payByCard"
          >
        </div>
        <div class="main-services__buttons pay-method__buttons">
          <div :class="{'hide-button': unpaidAndOneGood}" @click="$router.push('/terminal/barcode')" class="button button--left">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--light">
                Добавить товары
              </p>
            </div>
          </div>
          <div class="total">
            <h3 class="total__title">
<!--              Итого:-->
            </h3>
            <p class="total__price">

            </p>
          </div>
          <div @click="$router.push('/terminal/choose-services')" class="button button--right">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Добавить услуги
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import common from "../../components/mixins/common";
  export default {
    name: "ChoosePayMethod_",
    mixins: [common],

    data() {
      return {

      }
    },

    props: {

    },

    computed: {

    },

    mounted() {

    },

    methods: {
      payByCash() {
        this.$emit('pay-by-cash', true);
      },
      payByCard() {
        this.$emit('pay-by-card', true);
      },
    },

  }
</script>

<style scoped>

</style>
