import Vue from "vue";

export default Vue.extend({
  data: function () {
    return {
      person_id: null
    };
  },
  beforeMount() {
  },
  mounted() {
    this.showPerson()
  },
  created() {
    this.person_id = this.params.data.person_id
  },
  methods: {
    showPerson() {
      this.$router.push(`/lists/staff/${this.person_id}`)
    }
  },
  template: `
    <div style="display: flex; margin-left: 40px;margin-top: 10px">
    <button class="edit-person"></button>
    </div>
  `
});
