<template>
  <label
    :class="classLabel"
    :for="label || id"
  >
    {{ label }}

    <input
      :id="label || id"
      v-bind="$attrs"
      :class="classInput"
      :placeholder="placeholder"
      :value="value"
      :type="type"
      @input="$emit('input', $event.target.value)"
    >
    <slot />
  </label>
</template>

<script>
export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    classLabel: {
      type: String,
      default: ''
    },
    classInput: {
      type: [Array, Object, String],
      default: function () {
        return '';
      }
    },
    refInput: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
