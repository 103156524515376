<template>
  <main class="payment-card-main payment-card">
    <div class="container payment-card__container">
      <h1 class="payment-card__title">Оплата картой? </h1>
      <h3 class="payment-card__subtitle">Следуйте инструкции на экране терминала</h3>

      <img class="payment-card__icon" :src="require('../../assets/terminal/images/dest/card.svg')" alt="card img">
      <div v-if="kkmError" v-html="kkmError" class="payment-card__title" style="position: absolute; top: 720px; left: 230px;"></div>

      <div class="main-services__buttons" style="margin-top: 90px; margin-left: -40px;">
        <div @click="$router.go(-1)" class="button button--left">
          <div class="button__inner">
            <p class="button__inner-title button__inner-title--light">
              Назад
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import {apiClient} from "../../services/api";
  import payment from "../../components/mixins/payment";
  import common from "../../components/mixins/common";

  export default {
    name: "PayByCard",

    mixins: [payment, common],

    data() {
      return {
        inProgress: false,
        kkmError: ''
      }
    },

    computed: {
      totalPrice() {
        return this.fullPrice || 0;
      },

      selectedService() {
        return Object.values(this.$store.getters.selectedServices)[0] || {};
      },
    },

    created() {
      this.registerCheckFiscal(0, 0, true)
    },

    methods: {
      error(errors, kkmError) {
        console.log('Errors:', errors)
        if (kkmError) {
          this.inProgress = false
          this.kkmError = this.kkmErrorText(errors[1])
        }
      },

      executeSuccess(e) {
        if (!e.Status) {
          apiClient.post('/yc-transaction/terminal/new-bindgood', {
            account: 'Расчетный счет',
            person_id: this.$store.getters.clientByPhone.person_id,
            amount: this.totalPrice,
            client_id: this.$store.getters.clientByPhone.new_yc_id || null,
            record_id: this.$store.getters.record.id || 0,
            visit_id: this.$store.getters.record.visit_id || 0,
            services: this.$store.getters.selectedServices,
            staff_id: this.$store.getters.selectedMaster.id || 0,
            goods: this.goodsToTransaction(),
            qrcode: e.QRCode || null
          }).then(res => {
            if (res.data[1].success) {
              // if (this.goodsArePresent) {
              //   apiClient.post('/yc-transaction/terminal/bindgood', this.goodsToTransaction()).then(res => {
              //     console.log('bindgood:', res)
              //     this.$router.push('/terminal/done')
              //   })
              // } else {
                console.log('Success:', res)
                // this.$router.push('/terminal/done')
                this.$router.push('/terminal/print-receipt')
              // }
            } else {
              this.error(res, false)
            }
          }).catch(res => {
            this.error(res, false)
          }).finally(res => {

          })
          let receipt = {
            goods: this.goodsToTransaction(),
            total: this.totalPrice,
            services: this.$store.getters.selectedServices,
            qrCode: e.QRCode ?? '',
            numReceipt: e.CheckNumber ?? ''
          }
          localStorage.setItem("infoReceipt", JSON.stringify(receipt));
        } else {
          this.error([e.Status, e.Error], true)
        }
      }
    }
  }
</script>

<style scoped>

</style>
