<template>
  <section class="authorization">
    <div class="authorization__content">
      <div class="authorization__left auth-left">
        <img
          class="authorization__bg-img"
          src="../assets/images/Authorization/bg.jpg"
          alt="background-image"
        >
        <div class="auth-left__inner">
          <h2 class="auth-left__subtitle">
            Добро пожаловать в
          </h2>
          <h1 class="auth-left__title">
            BEAUTY INFORMATION SYSTEM
          </h1>
        </div>
      </div>
      <div class="authorization__right auth-right">
        <div class="auth-right__inner">
          <span class="logo authorization__logo">
            logo
          </span>
          <h2 class="auth-right__title">
            Войдите в BIS
          </h2>
          <h3 class="auth-right__subtitle">
            укажите данные для входа в систему
          </h3>

          <form
            class="auth-right__form auth-form"
            action=""
            @submit.prevent.stop.self="onSubmit"
            @keyup.enter.stop.prevent="onSubmit"
          >
            <p style="color: red">
              {{ error }}
            </p>
            <app-input
              id="login"
              v-model="event.email"
              class-label="auth-form__label"
              class-input="auth-form__input auth-form__input--login"
            />
            <app-input
              id="password"
              v-model="event.password"
              class-label="auth-form__label"
              :type="type"
              :class-input="{'auth-form__input':true,
                             'auth-form__input--password':true,
                             'auth-form__input--showPassword':type==='text'}"
            >
              <button
                class="auth-form__input--eye"
                @click.prevent.stop.self="showPassword"
              />
            </app-input>
            <div>
              <app-checkbox class="auth-form__checkbox">
                Запомнить меня
              </app-checkbox>
            </div>
            <app-button
              type="submit"
              class="auth-form__btn"
            >
              Войти
            </app-button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppInput from "../components/common/Input";
import AppCheckbox from "../components/common/Checkbox";
import AppButton from "../components/common/Button";

export default {
  name: "Authorization",
  components: {
    AppInput,
    AppCheckbox,
    AppButton
  },
  data() {
    return {
      type: 'password',
      event: {
        password: '',
        email: ''
      },
      error: null
    }
  },
  methods: {
    async onSubmit() {
      const formData = this.getFormData()
      try {
        await this.$store.dispatch('login', formData).then((res) => {
          const routes = [
            ['terminal', '/terminal/home', true],
            ['operator', '/call-center/first-visit'],
            ['Admin', '/lists/staff'],
          ]
          for (let item of routes) {
            if (res.user_data.roles.find(role => role.slug === item[0])) {
              if (item[2]) {
                location.href = item[1]
              } else {
                this.$router.push(item[1]);
              }
              return;
            }
          }
          this.$router.push('/');
        })
      } catch (error) {
        this.error = error.response.data.error
      }
    },
    getFormData() {
      return {
        email: this.event.email,
        password: this.event.password,
      }
    },
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text'
      } else {
        this.type = 'password'
      }
    }
  }
}
</script>

<style scoped>

</style>
