import Vue from "vue";

export default Vue.extend({
  data: function () {
    return {
      phone: null
    };
  },
  beforeMount() {
  },
  mounted() {
  },
  created() {
    this.setPhone(this.params.value)
  },
  methods: {
    setPhone(phone) {
      if (phone) this.phone = '+' + phone
    }
  },
  template: `
    <div>
    {{ phone }}
    </div>
  `
});
