<template>
  <label
    class="checkbox"
  >
    <input
      type="checkbox"
      :checked="value"
      @change="$emit('input', $event.target.checked)"
    >
    <span style="position: relative">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
