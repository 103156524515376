<template>
  <main class="main-services">
    <div class="container main-services__container">
      <h3 class="main-services__title title-bg">
        Откуда вы о нас узнали?
      </h3>
      <div class="category-list">
      <div
        @click="goToName(category)"
        v-for="category in categories"
        class="button-categories"
      >
        {{category.title}}
      </div>
    </div>
      </div>
    </main>
</template>

<script>
export default {
  name: 'Categories',
  created() {
    this.$store.dispatch('getCategories')
  },
  computed: {
    categories() {
      return this.$store.state.terminal.categories
    }
},
  methods: {
    goToName (category) {
      this.$store.commit('SET_CATEGORY', [category.id])
      this.$router.push('/terminal/name');
    }
  }
}
</script>

<style scoped>
.category-list {
  display: flex;
  flex-wrap: wrap;
  /*align-content: flex-start;*/
  /*height: 100%;*/
  padding-top: 144px

  }
.button-categories {
  font-family: Ki-Regular, serif;
  width: 256px;
  text-align: center;
  height: 48px;
  background: #4fd8db;
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -.03em;
  color: #17324d;
  line-height: 186%;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
