<template>
  <div>
    <label
      :class="classLabel"
      :for="label"
    >
      {{ label }}
    </label>
    <select
      :id="label"
      required
      :class="classSelect"
      :value="value"
      v-bind="$attrs"
      @change="$emit('input',$event.target.value)"
    >
      <option
        v-if="hiddenOption"
        :class="classHiddenOption"
        disabled
        selected
        value=""
      >
        {{ hiddenOption }}
      </option>
      <option
        v-for="option in options"
        :key="option[params]"
        :class="classOption"
        :selected="option === value"
        :value="option[secondParams]"
      >
        {{ option[params] }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    hiddenOption: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    classLabel: {
      type: String,
      default: ''
    },
    classSelect: {
      type: String,
      default: ''
    },
    classOption: {
      type: String,
      default: ''
    },
    classHiddenOption: {
      type: String,
      default: ''
    },
    params: {
      type: String,
      default: ''
    },
    secondParams: {
      type: String,
      default: ''
    }
  },
  mounted() {
    console.log(this.options)
  }
}
</script>

<style scoped>

</style>
