<template>
  <section
    v-if="person"
    class="edit-staff"
  >
    <router-link
      class="back-to-lists"
      to="/lists/staff"
    >
      Назад к списку
    </router-link>

    <div class="photos-list">
      <div
        class="edit-staff__photos photos-list__item "
      >
        <div
          v-if="!person || !person.avatar"
          class="photos-list__svg"
        >
          <photoSVG />
        </div>
        <img
          v-else
          class="photos-list__img"
          :src="person.avatar"
          alt="person avatar"
        >
      </div>
      <h3 class="edit-staff__name">
        {{ fullName }}
      </h3>
    </div>
    <nav class="edit-staff__nav nav-staff">
      <ul class="nav-staff__list">
        <li class="nav-staff__item">
          <button
            v-for="edit in edits"
            :key="edit.name"
            :class="['nav-staff__btn',{'active-edit':edit.name === currentEdit.name}]"
            @click.stop="currentEdit= edit"
          >
            <span v-if="edit.type === 'text'">
              {{ edit.name }}
            </span>

            <component
              :is="edit.name"
              v-if="edit.type === 'checkbox'"
              ref="toBIS"
              class="nav-staff__btn--BIS"
              :person="person"
            />
          </button>
        </li>
      </ul>
    </nav>
    <transition
      name="fade"
      mode="out-in"
    >
      <keep-alive>
        <component
          :is="currentEdit.component"
          :person="person"
          @submit="onSubmit"
          @removePerson="removePerson"
          @submitTOBIS="onSubmit"
          @submitIVideon="onSubmit"
        >
          <template v-slot:save>
            <app-button
              class="button"
              @click.stop.prevent="sendFormData"
            >
              <slot>Сохранить</slot>
            </app-button>
          </template>
        </component>
      </keep-alive>
    </transition>
  </section>
</template>


<script>
import AppInput from "../components/common/Input";
import AppCheckbox from "../components/common/Checkbox";
import AppButton from "../components/common/Button";
import {TheMask} from "vue-the-mask";
import AppTextarea from "../components/common/Textarea";
import EditIVideon from "../components/EditStaff/EditIVideon";
import EditGeneral from "../components/EditStaff/EditGeneral";
import EditYClients from "../components/EditStaff/EditYClients";
import EditPersonnel from "../components/EditStaff/EditPersonnel";
import photoSVG from "../assets/images/photo.svg?inline.svg"
import CheckBoxToBIS from "../components/common/CheckBoxToBIS";
import EditToBIS from "../components/EditStaff/EditToBIS";
import {mapGetters, mapState} from "vuex";

const edits = [
  {
    name: 'Общее',
    type: 'text',
    component: 'EditGeneral'
  },
  {
    name: 'iVideon',
    type: 'text',
    component: 'EditIVideon'
  },
  {
    name: 'yClients',
    type: 'text',
    component: 'EditYClients'
  },
  {
    name: 'Персонал',
    type: 'text',
    component: 'EditPersonnel'
  },
  {
    name: 'CheckBoxToBIS',
    type: 'checkbox',
    component: 'EditToBIS'
  }
];

export default {
  name: 'EditStaff',
  components: {
    AppInput,
    AppCheckbox,
    AppButton,
    TheMask,
    AppTextarea,
    EditIVideon,
    EditGeneral,
    EditYClients,
    EditPersonnel,
    CheckBoxToBIS,
    EditToBIS,
    photoSVG
  },
  data() {
    return {
      checked: false,
      edits: edits,
      currentEdit: edits[0],
      allFormData: {}
    }
  },
  computed: {
    person() {
      return this.$store.state.persons.person
    },
    fullName() {
      return `${this.person.lastname || ''} ${this.person.firstname || ''} ${this.person.fatherland || ''}`
    },
  },
  created() {
    this.$store.dispatch('getPersonById', this.$route.params.id)
  },
  methods: {
    onSubmit(formData) {
      Object.assign(this.allFormData, formData)
    },
    async sendFormData() {
      Object.assign(this.allFormData, {person_id: this.person.person_id, phone: '7' + this.person.phone})
      const response = await this.$store.dispatch("updatePerson", this.allFormData)
      if (response) await this.$router.push('/lists/staff')
    },
    async removePerson({person_id}) {
      const deleted = await this.$store.dispatch('removeClient', {person_id})
      if (deleted) await this.$router.push('/lists/staff')
    },
  }
}
</script>

<style lang="scss">


</style>
