<template>
  <main class="barcode-main">
    <div class="container barcode-main__container">
        <h3 class="barcode-main__title title-bg">
            Отсканируйте штрихкод
        </h3>
        <div class="main-content-bg">
            <div class="barcode-main__content ">
                <img :src="require('../../assets/terminal/images/dest/barcode.svg')" class="barcode-main__code" alt="barcode">
                <input @keypress.enter="add($event)" class="barcode-input" :class="{'barcode-input-hidden': !isDev}" type="text" ref="barcode" />
                <p class="barcode-main__subtitle">Поднесите штрихкод товара <br> к терминалу</p>
            </div>
            <div class="main-services__buttons">
                <div @click="$router.push('/terminal/products')" class="button button--left">
                    <div class="button__inner">
                        <p class="button__inner-title button__inner-title--light">
                            Товары
                        </p>
                    </div>
                </div>
                <div class="total">
                    <h3 class="total__title">
                        Итого:
                    </h3>
                    <p class="total__price">400 р</p>
                </div>
                <div @click="$router.push('/terminal/choose-pay-method')" class="button button--right ">
                    <div class="button__inner">
                        <p class="button__inner-title button__inner-title--black">
                            Оплатить
                        </p>
                    </div>
                </div>
            </div>
        </div>
      <footer class="footer">
        <div class="footer__container" />
      </footer>
    </div>
  </main>
</template>

<script>
  import {apiClient} from "../../services/api";

  export default {
    name: "Barcode",

    data() {
      return {
        interval: null,
        goods: null,
        barcodeActive: true,
        isDev: process.env.NODE_ENV === 'development'
      }
    },

    computed: {
    },

    watch: {

    },

    mounted() {
      this.focus();
    },

    created() {
      apiClient.get('/goods/all-goods-yc').then(res => {
        this.goods = res.data.data;
        let plusGoods = {};
        this.goods.forEach(good => {
          good.actual_amounts.forEach(amount => {
            if (amount.amount > 0)
              plusGoods[good.barcode] = amount.amount;
          });
        });
              console.log(plusGoods);

      });
    },

    methods: {
      add(e) {
        let barcode;
        if (e.target){
          if (!this.barcodeActive) {
            this.$refs["barcode"].value = '';
            return;
          }
          barcode = JSON.parse(JSON.stringify(e.target.value)).trim();
          this.$refs["barcode"].value = '';
          if (barcode == '')
            return;
          this.barcodeActive = false;
        } else {
          barcode = e;
        }

        if (this.goods === null) {
          setTimeout(() => {
            this.add(barcode);
          }, 200);
        } else {
          this.add_(barcode);
        }
      },

      add_(barcode) {
        const good = this.goods.find(good => good.barcode == barcode);
        console.log('search:', good);
        if (good) {
          let products = this.$store.getters.selectedProducts;
          if (!products.find(good => good.barcode == barcode)) {
            good.count = 1;
            products.push(good);
          }
        }
        this.barcodeActive = true;
        console.log('prdcts', this.$store.getters.selectedProducts);
        this.$router.push('/terminal/products');
      },

      focus() {
        this.focus_();
        this.interval = setInterval(() => {
          this.focus_();
        }, 100)
      },

      focus_() {
        this.$nextTick(() => {
            this.$refs["barcode"].focus()
        })
      },

      next() {
        // this.$router.push('/terminal/choose-master');
      },
    },

    beforeDestroy() {
      clearInterval(this.interval);
    }

  }
</script>

<style scoped>
  .total {
    visibility: hidden;
  }
  .barcode-input {
    position: absolute;
    z-index: 1000009999;
    color: brown;
  }
  .barcode-input-hidden {
    top: -100px;
    left: -100px;
  }
</style>
