<template>
  <section>
    <div class="wrapper-header">
      <div
        style="display: flex;"
      >
        <app-button
          class="button add-user-link"
          @click.exact="modal=!modal"
        >
          Добавить пользователя
        </app-button>
        <div
          v-if="modal"
          class="modal-lists-staff-back"
          @click="modal=false"
        >
          <div
            class="modal-lists-staff"
          >
            <router-link
              exact
              class="button button--add add-user-link"
              to="/lists/staff/new-staff"
            >
              Новый
            </router-link>
            <router-link
              exact
              class="button button--add add-user-link"
              to="/lists/staff/ivideon"
            >
              из IVideon
            </router-link>
          </div>
        </div>
        <AppInput
          id="filter-text-box"
          v-model="filterText"
          class-input="ag-theme-balham-search"
          type="text"
          placeholder="Поиск"
          @input="onFilterTextBoxChanged()"
        />
      </div>
      <app-pagination />
    </div>
    <ag-grid-vue
      style="width: 100%; height: 85vh;"
      :get-row-style="getRowStyle"
      :grid-options="gridOptions"
      :row-data="rowData"
      class="ag-theme-balham"
      :column-defs="columnDefs"
      :row-heigh="rowHeight"
      :default-col-def="defaultColDef"
      :animate-rows="true"
      :framework-components="frameworkComponents"
      :loading-cell-renderer="loadingCellRenderer"
      :loading-cell-renderer-params="loadingCellRendererParams"
      :get-row-height="getRowHeight"
      @grid-ready="onGridReady"
      @column-resized="onColumnResized"
      @column-visible="onColumnVisible"
    />
  </section>
</template>


<script>
import {AgGridVue} from 'ag-grid-vue'
import AppPagination from '../components/Pagination'
import EditStaff from '../components/ag-grid-components/EditStaff'
import PhotoListsStaff from "../components/ag-grid-components/PhotoListsStaff";
import ListsStaffPhone from "../components/ag-grid-components/ListsStaffPhone";
import AppInput from "../components/common/Input";
import Loader from "../components/ag-grid-components/Loader";
import AppButton from "../components/common/Button";


export default {
  name: 'ListsStaff',
  components: {
    AppButton,
    AppPagination,
    AgGridVue,
    AppInput
  },
  data() {
    return {
      modal: false,
      gridOptions: null,
      filterText: '',
      columnDefs: null,
      rowData: null,
      gridApi: null,
      defaultColDef: null,
      rowHeight: null,
      frameworkComponents: null,
      loadingCellRendererParams: null,
      getRowStyle: null,
      loadingCellRenderer: null,
    }
  },
  computed: {
    persons() {
      const {persons} = this.$store.state.persons
      return persons
    },
  },
  watch: {
    persons(persons) {
      this.rowData = persons
    }
  },
  created() {
    this.$store.dispatch('getAllPersons')
  },
  async beforeMount() {
    this.gridOptions = {}
    this.columnDefs = [
      {
        headerName: 'Имя',
        field: 'firstname',
        lockPosition: true,
        editable: true,
        cellEditor: 'editStaffCellRenderer',
        width: 162
      },
      {
        headerName: 'Фамилия',
        field: 'lastname',
        editable: true,
        cellEditor: 'editStaffCellRenderer',
        width: 166,
        lockPosition: true,

      },
      {
        headerName: 'Отчество',
        field: 'fatherland',
        width: 166,
        editable: true,
        cellEditor: 'editStaffCellRenderer',
        lockPosition: true
      },
      {
        headerName: 'Телефон',
        field: 'phone',
        cellRenderer: 'phoneListsStaffCellRenderer',
        lockPosition: true,
        editable: true,
        cellEditor: 'editStaffCellRenderer',
        width: 165
      },
      {
        headerName: 'Точка',
        field: 'department_name',
        lockPosition: true,
        editable: true,
        cellEditor: 'editStaffCellRenderer',
        width: 349
      },
      {
        headerName: 'Фото',
        cellRenderer: 'photoListsStaffCellRenderer',
        field: 'avatar',
        editable: true,
        cellEditor: 'editStaffCellRenderer',
        width: 146,
        lockPosition: true,
      },
    ]
    this.frameworkComponents = {
      photoListsStaffCellRenderer: PhotoListsStaff,
      editStaffCellRenderer: EditStaff,
      phoneListsStaffCellRenderer: ListsStaffPhone,
      customLoadingCellRenderer: Loader
    }
    this.loadingCellRenderer = 'customLoadingCellRenderer';
    this.loadingCellRendererParams = {loadingMessage: 'One moment please...'};
    this.defaultColDef = {
      flex: 1
    }
    this.getRowStyle = params => {
      if (params.node.rowIndex % 2 === 0) {
        return {
          background: '#FDFDFD',
          border: '1px solid #FBFBFB',
          borderRadius: '3px'
        }
      }
    }
    this.rowData = []
  },
  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.filterText)
    },
    onColumnResized(params) {
      params.api.resetRowHeights()
    },
    onColumnVisible(params) {
      params.api.resetRowHeights()
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.resize()
    },
    resize() {
      setTimeout(() => {
        this.gridApi.resetRowHeights();
      }, 100);
    },
    getRowHeight(params) {
      return params.node.rowHeight = 50;
    },
  }
}
</script>

<style lang="scss">
.add-user-link {
  display: inline-block;
}

</style>
