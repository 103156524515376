<template>
  <main class="main">
    <div class="container main__container">
      <h2 class="main__title"></h2>
      <h3 class="main__subtitle">Добро пожаловать в Стрижевский!</h3>

      <div @click="next" class="buttons buttons__container">
        <div class="button button--right">
          <div class="button__inner">
            <p class="button__inner-title button__inner-title--black">
              Далее
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import {wsEcho, apiClient} from "../../services/api";
  import cookie from 'js-cookie';

  export default {
    name: "Home",

    data() {
      return {
        interval: null,
        intervalCheckConnection: null,
        currentEcho: null,
        current: 0,
        count: 0,
        channel: `detected_user.` + JSON.parse(cookie.get('user')).user_data.user.id
      }
    },

    mounted() {
      this.clearAll();
      this.echoConnect()
      this.intervalCheckConnection = setInterval(() => {
        if (!this.currentEcho || !this.currentEcho.connector.pusher.connection.connection)
          this.echoConnect()
      }, 5000)

      if (cookie.get('user')) {
        apiClient.get('/departments/' + JSON.parse(cookie.get('user')).user_data.user.department_id)
          .then(res => {
            localStorage.setItem('terminal_department', JSON.stringify(res.data.data))
          })
        apiClient.get('/list-staff-yc')
          .then(res => {
            localStorage.setItem('terminal_list_staff', JSON.stringify(res.data.staffYC))
          })
      }
    },

    methods: {
      timerCount() {
        this.current--;
        if (this.current < 1) {
          clearInterval(this.interval);
          this.count = 0;
        }
      },

      next() {
        this.$router.push('/terminal/phone');
      },

      echoConnect() {
        clearInterval(this.interval);
        if (this.currentEcho)
          this.currentEcho.leave(this.channel)
        this.currentEcho = wsEcho()
          this.currentEcho.join(this.channel)
          .here((e) => {
            console.log('joined to detected_user channel, here:', e);
          })
          .listen('.detected_user.detected', (e) => {
            console.log('detected:', e);
            console.log('face_id: ', e.data.face_id);
            this.$store.commit('SET_FACE_ID', e.data.face_id);
            this.$store.commit('SET_CLIENT_BY_PHONE', e.data.user);
            this.$router.push('/terminal/hello');
          })
          .listen('.detected_user.not_detected', (e) => {
            console.log('not_detected:', e);
            console.log('face_id: ', e.data.face_id);
            this.$store.commit('SET_FACE_ID', e.data.face_id);

            this.count++
            if (this.count > 1)
              this.$router.push('/terminal/unrecognized');

            if (this.current < 1) {
              this.current = 2
              this.interval = setInterval(() => {
                this.timerCount();
              }, 1000);
            }

          })
      },

      clearAll() {
        this.$store.commit('SET_CLIENT_BY_PHONE', '');
        this.$store.commit('SET_SERVICES', []);
        this.$store.commit('SET_SELECTED_SERVICES', {});
        this.$store.commit('SET_SELECTED_MASTER', []);
        this.$store.commit('SET_TOTAL_PRICE', 0);
        this.$store.commit('SET_ENTERED_PHONE', '');
        this.$store.commit('SET_FACE_ID', '');
        this.$store.commit('SET_RECORD', {});
        this.$store.commit('SET_UNPAID_RECORDS', []);
        this.$store.commit('SET_SELECTED_PRODUCTS', []);
        this.$store.commit('SET_CASH_PAID', 0);
      }
    },

    beforeDestroy() {
      if (this.currentEcho)
        this.currentEcho.leave(this.channel);
      clearInterval(this.intervalCheckConnection);
      clearInterval(this.interval);
    }
  }
</script>

<style scoped>

</style>
