import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import 'reset-css'
import 'normalize.css'

import Multiselect from "vue-multiselect";
import cookie from 'js-cookie'
import {apiClient} from "./services/api";

Vue.use(Vuelidate)

Vue.component('default-layout', () => import(/* webpackChunkName: "default_layout" */ "./layouts/DefaultLayout"))
Vue.component('auth-layout', () => import(/* webpackChunkName: "auth_layout" */ "./layouts/AuthLayout"))
Vue.component('terminal-layout', () => import(/* webpackChunkName: "terminal_layout" */ "./layouts/TerminalLayout"))
Vue.component('multiselect', Multiselect)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  created() {
    const userCookie = cookie.get('user')
    const jwtCookie = cookie.get('jwt')
    if (jwtCookie && userCookie) {
      this.$store.commit('SET_USER_DATA', JSON.parse(userCookie))
    }
    apiClient.interceptors.response.use(response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout').then(r =>   this.$router.push('/authorization'))
        }
        return Promise.reject(error)
      })
  },
  render: h => h(App)
}).$mount('#app')


const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent);
if (isMobile) {
  console.log(isMobile)
} else {
  console.log(2)
}
