<template>
  <main class="payment-card-main payment-card">
    <div class="container payment-card__container">
      <h1 class="payment-card__title">Оплата картой</h1>
      <h3 class="payment-card__subtitle">Следуйте инструкции на экране терминала</h3>

      <img class="payment-card__icon" :src="require('../../assets/terminal/images/dest/card.svg')" alt="card img">
      <div v-if="noPaper" v-html="noPaper" class="payment-card__title" style="position: absolute; top: 720px; left: 230px;"></div>
      <div class="main-services__buttons" style="margin-top: 90px; margin-left: -40px;">
        <div @click="$router.go(-1)" class="button button--left">
          <div class="button__inner">
            <p class="button__inner-title button__inner-title--light">
              Назад
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

  export default {
    name: "PayByCard_",

    props: {
      noPaper: {
        default: false
      },
    },

    data() {
      return {

      }
    },

    computed: {
      totalPrice() {

      },

      selectedService() {

      },
    },

    mounted() {

    },

    methods: {
    }
  }
</script>

<style scoped>

</style>
