import {apiClient} from '../../services/api'

export default {
  state: {
    role: []
  },
  mutations: {
    SET_ROLE(state, role) {
      state.role = role
    }
  },
  actions: {
    async getAllRole({commit, dispatch}) {
      try {
        const {data: {data}} = await apiClient.get('/roles')
        commit('SET_ROLE', data)
      } catch (error) {
        const notification = {
          type: 'error',
          message: `getAllRole have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    }
  },
  getters: {}
}

