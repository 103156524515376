<template>
  <section class="lists">
    <main class="lists__article" />
  </section>
</template>

<script>

export default {
  name: 'Lists',
  data() {
    return {}
  }
}
</script>

<style scoped>
.lists {
  display: flex;
  width: 100%;
}

.lists__article {
  width: 100%;
}

</style>
