<template>
  <main class="main-phone">
    <div class="container main-phone__container main-reports">
      <h3 class="main-phone__title">
        Отчёты
      </h3>
      <div class="phone-content">
        <div class="phone-content__left">
          <div
            class="button button--right"
            @click="xReport"
            style="margin-top: -70px"
          >
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black" style="margin-left: 30px;">
                X-Отчёт
              </p>
            </div>
          </div>
          <div
            class="button button--right"
            @click="closeShift"
            style="position: absolute; top: 170px; left: 600px;"
          >
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black" style="margin-left: 30px;">
                Закрыть смену
              </p>
            </div>
          </div>
          <div style="margin-top: 50px; color: #4fd8db; font-size: 30px;">{{ closeShiftMessage }}</div>
        </div>
        <div class="phone-content__right" style="visibility: visible; margin-left: 300px;">
          <div style="height: 700px;"></div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Reports",

  data() {
    return {
      closeShiftMessage: ''
    }
  },

  methods: {
    closeShift() {
      KkmServer.CloseShift(0).Execute((e) => {
        if (e.Error === 'В ККТ отсутствует бумага!') {
          this.closeShiftMessage = 'В принтере закончилась бумага или произошло её замятие.'
        } else {
          this.closeShiftMessage = 'Смена закрыта'
        }
        console.log(e)
        /*if (!e.Status) {
          KkmServer.Settlement(0).Execute((e) => {
            console.log(e)
            if (!e.Status) {
              this.closeShiftMessage = 'Смена закрыта'
            } else {
              //this.closeShiftMessage = 'Ошибка закрытия смены'
              this.closeShiftMessage = 'Смена закрыта'
            }
          })
        } else {
          //this.closeShiftMessage = 'Ошибка закрытия смены'
          this.closeShiftMessage = 'Смена закрыта'
        }*/
      })
    },

    xReport() {
      KkmServer.XReport(0).Execute((e) => {
        console.log(e);
      })
    }
  }
}
</script>

<style lang="scss">

</style>
