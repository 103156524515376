<template>
  <main class="main-print-receipt">
    <div class="container print-receipt__container">
      <h3 class=" title-bg">
        Электтронный чек
      </h3>
      <div class="main-content-bg">
        <div id="table-container">
          <div id="table-container__bt">
           <div class="table-container__site" style="margin-left: auto; margin-top: -50px">
             <div style="margin-bottom: 20px">Адрес сайта для просмотра чека:
               platformaofd.ru</div>
             <div style="">Адрес сайта для проверки чека:
               https://www.nalog.gov.ru/</div>
               </div>
           <div class="number-receipt" style="">
           <div>Кассовый чек №{{info.numReceipt}}</div>
           <div>{{date}}</div>
            </div>
            <div class="requisites" style="">
              <div>{{$store.getters.loggedIn.requisites.main_name}}</div>
              <div>ИНН {{$store.getters.loggedIn.requisites.inn}}</div>
              <div>Вид налогооблажения: {{$store.getters.loggedIn.requisites.type_tax}} </div>
            </div>
            <div class="info-receipt">
              <div>
            <div class="table-service">
              <tbody>
              <tr v-for="(service,i) in info.services" :key="i">
                <td class="val__left">{{service.title}}</td>
                <td class="val__right">{{service.price}}</td>
              </tr>

                </tbody>
            </div>
                <tbody>
                <tr class="table-total">
                  <td >ИТОГО</td>
                  <td>{{info.total}}</td>
                </tr>
                <tr class="table-NDS">
                  <td class="val__left" >Сумма без НДС</td>
                  <td class="val__right" >{{info.total}}</td>
                </tr>
                </tbody>
               </div>

            <div class="qr__code"><qrcode-vue level="L" foreground="#fff" background="#17324D" :value="info.qrCode" :size="size"></qrcode-vue></div>
          </div>
    <div v-if="email" class="footer-text">Электронный чек отправлен на адрес: {{email}}</div>
          </div>
          </div>
        <div class="print-receipt__buttons">
          <div></div>
<!--          <div  @click="$router.push('/terminal/home')"-->
<!--               class="button button&#45;&#45;left">-->
<!--            <div class="button__inner">-->
<!--              <p class="button__inner-title button__inner-title&#45;&#45;light">-->
<!--                На главный экран-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->

          <div

            class="button button--right"
            @click=""
          >
            <div class="button__inner" @click="next">
              <p class="button__inner-title button__inner-title--black">
                Далее
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="footer__container"/>
    </footer>
  </main>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
export default {
name: "PrintReceipt",
  data() {
    return {
      email: JSON.parse(localStorage.getItem("emailClient")) ?? this.$store.getters.clientByPhone.email  ?? null,
      date: null,
      // qr: 't=20220401T141800&s=1000.00&fn=9960440300162173&i=8692&fp=789002290&n=1',
      size: 250,
      info:  JSON.parse(localStorage.getItem("infoReceipt")) ?? []
    }
  },
  components: {
    QrcodeVue,
  },
  mounted() {
    this.dateReceipt(this.info.qrCode)
  },
  methods: {
  dateReceipt (data) {
    let mySubString =(data.substr(2, 13));
    let year = mySubString.substr(0, 4)
    let day = mySubString.substr(6, 2)
    let month= mySubString.substr(4, 2)
    let hour = mySubString.substr(9, 2)
    let min = mySubString.substr(11, 2)
    this.date = (day + '.' + month + '.' + year + ' ' + hour + ':' + min)
  },
  next () {
    this.$router.push('/terminal/done')
  }
  }
}
</script>

<style scoped lang="scss">
.main-print-receipt {
  position: relative !important;

}
.table-container__site{
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 320px;
  font-family: Montserrat;
  font-size: 18px;
  color: #4FD8DB;
  line-height: 1.4;

}


.requisites {
  font-family: Montserrat;
  color: #4FD8DB;
  margin-top: 40px;
  font-size: 24px;
  width: 658px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
}
.info-receipt {
  display: grid;
  grid-template-columns: 650px 100px;
  font-family: Montserrat;
  color: #4fd8db ;
  font-size: 24px;

  td {
    padding-bottom: 5px;
    padding-top: 0;
    padding-left: 0;
  }
  .table-total {
    font-weight: bold;
    td {
      padding-top: 30px !important;
    }
   }
  .table-NDS{
    td {
      padding-top: 30px !important;
    }
  }
  .qr__code{
   margin-left: 80px;

  }
  .table-service{
    max-height: 150px;
    color: #4FD8DB;
    margin-top: 40px;
    overflow: visible auto;
  }
  .val__right {
    text-align: left;
    width: 10%;
  }
  .val__left {
    width: 90%;
    text-align: left;
  }
}
.footer-text{
  padding-top: 26px;
  text-align: center;
  font-family: Montserrat;
  color: #4fd8db ;
  font-size: 20px;
}

.number-receipt {
  font-family: Montserrat;
  color: #4FD8DB;
  margin-top: -50px;
  font-weight: bold;
  font-size: 24px;
  width: 658px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
}
#table-container {
  width: 100%;
  //height: 604px;
  overflow: hidden;
  padding-top: 73px;

}
#table-container__bt {
  height: 100%;
  //margin-right: -50px;
  padding-right: 42px;
  padding-left: 42px;
  display: flex;
  flex-direction: column;

}
  .print-receipt__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 18px 0px 0 0;
  }

  .print-receipt__container {
    min-height: 96vh;
  }


</style>
